/** @format */

import React, { Component, Fragment } from 'react';
// import axios from 'helpers/axios';
import {
  success as notifySuccess,
  error as notifyError,
} from '@bonlineza/luxity-lib/Notify';
import api from 'constants/api';
import IconHeader from 'components/IconHeader';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import requestErrorMessages from 'constants/requestErrorMessages';
// import CommunicationPreferences from '@bonlineza/luxity-lib/CommunicationPreferences';
import AccountDetails from './components/AccountDetails';
import ProfileInformation from './components/ProfileInformation';
import RequestOTPForm from './components/RequestOTP';
import PasswordForm from './components/PasswordForm';

class Profile extends Component {
  static defaultProps = {
    onAccountUpdated: () => null,
    onAccountLoaded: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      changePasswordFormVisible: false,
      // preference: null,
    };
  }

  getActionCallback = (name) => {
    switch (name) {
      case 'update_profile':
        return () => {
          this.profilePageReady.refreshPage();
          notifySuccess('Profile Updated');
        };

      case 'otp_requested':
        return () => {
          notifySuccess('OTP sent');
          this.setState({ changePasswordFormVisible: true });
        };

      case 'password_updated':
        return () => {
          notifySuccess('Password has been updated!');
          this.setState({ changePasswordFormVisible: false });
        };

      case 'update_account':
        return () => {
          this.props.onAccountUpdated();
          this.accountPageReady.refreshPage();
          notifySuccess('Account Updated');
        };

      case 'update_account_password_error':
        return () => {
          notifyError('Unauthorized');
        };

      case 'profile_error':
        return () => {
          notifyError('Some fields where entered incorrectly');
        };

      case 'generic_error':
        return () => {
          notifyError(requestErrorMessages.RESPONSE_500);
        };

      default:
        return null;
    }
  };

  setPasswordFormVisible = (flag = true) => {
    this.setState({ changePasswordFormVisible: flag });
  };

  // setPreference = (data) => {
  //   this.setState({ preference: data });
  // };

  notificationHeader = () => (
    <IconHeader title="Notification Preference" svgName="alert" />
  );

  render() {
    return (
      <div className="profile">
        <IconHeader
          title="My Account | Personal Information"
          svgName="account"
        />
        <AxiosPageReadyWrapper
          ref={(inst) => {
            this.profilePageReady = inst;
          }}
          url={api.user.GET_PROFILE}>
          <AxiosPageReadyContext.Consumer>
            {({ data: profile = null }) => (
              <Fragment>
                <div className="section profile__section">
                  <ProfileInformation
                    profile={profile}
                    actionCallback={this.getActionCallback}
                  />
                </div>
                {/* <AxiosPageReadyWrapper
                  method="get"
                  url={api.user.COMMUNICATION_PREFERENCE}
                  onData={({ data }) => {
                    this.setPreference(data);
                  }}>
                  <CommunicationPreferences
                    header={this.notificationHeader()}
                    requestInstance={axios}
                    updateNotificationsApi={
                      api.user.COMMUNICATION_PREFERENCE_UPDATE
                    }
                    preferenceData={this.state.preference}
                  />
                </AxiosPageReadyWrapper> */}
                <IconHeader title="Bank Account Details" svgName="account" />
                <AxiosPageReadyWrapper
                  onData={({ data }) => {
                    this.props.onAccountLoaded(data);
                  }}
                  ref={(inst) => {
                    this.accountPageReady = inst;
                  }}
                  url={api.user.BANK_DETAILS}>
                  <AxiosPageReadyContext.Consumer>
                    {({ data: bankDetails = null }) => (
                      <div className="section profile__account-details">
                        <AccountDetails
                          bankDetails={bankDetails}
                          actionCallback={this.getActionCallback}
                        />
                      </div>
                    )}
                  </AxiosPageReadyContext.Consumer>
                </AxiosPageReadyWrapper>

                <IconHeader title="Change Password" svgName="account" />
                <div className="section">
                  {this.state.changePasswordFormVisible ? (
                    <Fragment>
                      <p>Enter your OTP and new Password</p>
                      <PasswordForm
                        actionCallback={this.getActionCallback}
                        onCancel={() => this.setPasswordFormVisible(false)}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p>
                        To change your password, you need an OTP, select how you
                        want to receive your OTP, then enter your password
                      </p>
                      <RequestOTPForm
                        actionCallback={this.getActionCallback}
                        email={profile.email}
                        country_code={profile.contact_number_country?.code}
                        phone_number={profile.contact_number}
                      />
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </AxiosPageReadyContext.Consumer>
        </AxiosPageReadyWrapper>
      </div>
    );
  }
}

export default Profile;
