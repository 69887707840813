import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import Notification from 'vendor/Notification';
import api from 'constants/api';
import axios from 'helpers/axios';
import {
  error as notifyError,
  success as notifySuccess,
} from '@bonlineza/luxity-lib/Notify';
import EllipsisLoader from '@bonlineza/b-lib/EllipsisLoader';
import GAButton from '../../GAButton';
import GetSvg from '../../../vendor/GetSvg';
import requestErrorMessages from '../../../constants/requestErrorMessages';

const InviteFriend = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const onSubmit = (formData) => {
    setIsLoading(true);
    return axios
      .post(`${api.user.INVITE_A_FRIEND}`, formData)
      .then(() => {
        setIsLoading(false);
        notifySuccess('Invite sent.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((data) => {
        setIsLoading(false);
        if (data.status === 422) {
          const errorMessagesArray = [];
          if ('invitee_contact' in data.data) {
            errorMessagesArray.push(data.data.invitee_contact);
          }
          if ('invitee_name' in data.data) {
            errorMessagesArray.push(data.data.invitee_name);
          }
          setErrorMessages(errorMessagesArray);
        } else if (data.status === 500) {
          notifyError(requestErrorMessages.RESPONSE_500);
        }
      });
  };

  if (isLoading) {
    return <EllipsisLoader />;
  }

  return (
    <Fragment>
      <div className="coupons__invite-a-friend">
        <Notification
          svgName="refer_a_friend"
          renderContent={() => (
            <div>
              <p>
                Invite your friends to Luxity. Share a R500 voucher with your
                friend and receive a R500 voucher in return when they make their
                first purchase.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="coupons__input-fields">
                  <div className="coupons__name">
                    <input
                      type="text"
                      name="invitee_name"
                      placeholder="Enter friends name"
                      {...register('invitee_name', {
                        required: 'The name field is required.',
                      })}
                    />
                  </div>
                  {errors.invitee_name && <span>This field is required</span>}
                  <div className="coupons__email">
                    <input
                      type="text"
                      name="invitee_contact"
                      placeholder="Enter friends email or number"
                      {...register('invitee_contact', {
                        required: 'The email or number field is required.',
                      })}
                    />
                    <GAButton
                      label="cancel_payment_button"
                      className="action-button card-btn send-mail"
                      type="submit">
                      <GetSvg svg="send" wrapperClass="svg" />
                    </GAButton>
                  </div>
                </div>
              </form>
              {errors.invitee_contact && <span>This field is required</span>}
              {errorMessages.length > 0 && (
                <div className="coupons__error">
                  <img
                    src="/assets/svgs/alert.svg"
                    className="coupons__error-img"
                    alt="alert icon"
                  />
                  {errorMessages.map((message) => (
                    <p className="coupons__error-text">{message}</p>
                  ))}
                </div>
              )}
            </div>
          )}
        />
      </div>
    </Fragment>
  );
};

export default InviteFriend;
