import React, { Fragment } from 'react';
import GqlQueryList from 'vendor/GqlQueryList';
import { getOrdersListQuery } from 'components/Orders/query';
import Coupon from 'components/Coupons';
import Orders from 'components/Orders';
import Sales from 'components/Sales';
import { getSalesListQuery } from 'components/Sales/query';
import IconHeader from 'components/IconHeader';
import Add from '@bonlineza/luxity-lib/svg/Add';
import Minus from '@bonlineza/luxity-lib/svg/Minus';
import SvgWrapper from '@bonlineza/luxity-lib/svg/SvgWrapper';
import Accordion from '@bonlineza/luxity-lib/Accordion';

const Dashboard = () => {
  const initialResultLimit = 5;

  const AddIcon = () => (
    <SvgWrapper>
      <Add />
    </SvgWrapper>
  );
  const CloseIcon = () => (
    <SvgWrapper>
      <Minus />
    </SvgWrapper>
  );
  const OrdersIconHeader = () => (
    <div className="orders-icon-header">
      <IconHeader title="My Orders" svgName="my_order" />
    </div>
  );
  const SalesIconHeader = () => (
    <div className="orders-icon-header ">
      <IconHeader title="My Sales" svgName="my_sale" />
    </div>
  );
  return (
    <Fragment>
      <Coupon showInviteAFriend />
      <Accordion
        identifier="orders"
        containerClass="dashboard-accordion-container"
        closeIndicator={CloseIcon}
        openIndicator={AddIcon}
        title={OrdersIconHeader()}
        defaultHeight="auto">
        <GqlQueryList
          showIncreaseButton
          showPageNavButtons
          initialLimitState={initialResultLimit}
          gqlQueryFn={() => getOrdersListQuery(initialResultLimit)}
          listKeyName="client_sale_list">
          <Orders />
        </GqlQueryList>
      </Accordion>

      <Accordion
        identifier="sales"
        containerClass="dashboard-accordion-container"
        closeIndicator={CloseIcon}
        openIndicator={AddIcon}
        title={SalesIconHeader()}
        defaultHeight="auto">
        <GqlQueryList
          showIncreaseButton
          showPageNavButtons
          initialLimitState={initialResultLimit}
          gqlQueryFn={() => getSalesListQuery(initialResultLimit)}
          listKeyName="client_purchase_list">
          <Sales />
        </GqlQueryList>
      </Accordion>
    </Fragment>
  );
};

export default Dashboard;
