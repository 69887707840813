import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * MobileMenu provides a simple scaffold for implementing a menu, especially for Mobile websites
 */
const MobileMenu = ({ baseClassName, closeAction, isOpen, children }) => {
  const menuContentRef = useRef();
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuContentRef && !menuContentRef.current.contains(e.target)) {
        closeAction();
      }
    };
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeAction]);

  return (
    <div className={`${baseClassName}${isOpen ? '--active' : ''}`}>
      <div className={`${baseClassName}__overlay`} />
      <div className={`${baseClassName}__content`} ref={menuContentRef}>
        {children}
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  /** css class name assigned to root div. All child css class names are appended
   from this baseClassName  */
  baseClassName: PropTypes.string,
  /** if true, MobileSideMenu is visible */
  isOpen: PropTypes.bool,
  /** callback when the menu needs to be closed */
  closeAction: PropTypes.func.isRequired,
};

MobileMenu.defaultProps = {
  baseClassName: 'mobile-side-menu',
  isOpen: false,
};

export default MobileMenu;
