import React from 'react';
import GetSvg from '../../GetSvg';

const Notification = ({ notificationData, closeNotification }) => {
  const meta = JSON.parse(notificationData.meta);
  const dismissNotification = (notification_id) => {
    closeNotification(notification_id);
  };

  return (
    <div
      className="luxity-notification"
      style={{
        background: meta?.background || '#555555',
        color: meta?.color || '#555555',
      }}>
      <div className="luxity-notification__wrapper">
        <GetSvg svg="light_bulb" wrapperClass="svg" />
        <div
          className="luxity-notification__content"
          dangerouslySetInnerHTML={{ __html: notificationData.content }}
        />
        {notificationData.can_dismiss && (
          <div className="luxity-notification__close">
            <button
              className="luxity-notification__close__button"
              type="button"
              data-notification-id={notificationData.id}
              onClick={() => {
                dismissNotification(notificationData.id);
              }}>
              X
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
