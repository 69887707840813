import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from 'vendor/Notification';
import LuxityNotification from 'vendor/LuxityNotification';

const BodyNotification = ({ requireAccountDetails }) => {
  return (
    <Fragment>
      {requireAccountDetails ? (
        <Notification
          renderContent={() => (
            <Fragment>
              <div className="notification__text-wrapper">
                <p className="notification__text-wrapper__body--no-margin">
                  <Fragment>
                    <strong>Account Details: </strong>
                    to ensure you are paid out swiftly once payment is due,
                    please update&nbsp; Bank Account Details &nbsp;in&nbsp;
                    <Link to="/profile">your profile.</Link>
                  </Fragment>
                </p>
              </div>
            </Fragment>
          )}
        />
      ) : null}
      <LuxityNotification />
    </Fragment>
  );
};

export default BodyNotification;
