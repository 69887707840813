import React, { Fragment } from 'react';
import config from 'config/config.json';
import GALink from 'components/GALink';

export default function NoPurchases() {
  return (
    <Fragment>
      <div className="orders__item item u-text--center">
        <h6>
          Currently you have no purchases with Luxity. Time to change that,
          fashionista!
        </h6>
        <div>
          <GALink
            href={config.LUXITY_SHOP_URL}
            className="btn--luxity"
            label="Shop Now"
            target="_blank"
            rel="noopener noreferrer">
            Shop Now
          </GALink>
        </div>
      </div>
    </Fragment>
  );
}
