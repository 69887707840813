export const getOrdersListQuery = (initialLimit = 5) => `
  {
    client_sale_list( limit: ${initialLimit}, page: 1, full_return: false, cancelled: false, luxity_sale_status: current){
        data{
            id
            sale_invoices{
                outstanding_amount
                remaining_days
            }
            sale_quote{
              total
              sale_quote_lines{
                  total
                  product{
                    image
                }
              }
            }
            sale_order{
                total
                sale_order_number
                sale_order_lines{
                    total
                    product{
                        image
                    }
                }
            }

        }
        per_page
        total
        current_page
        last_page
        from
    }
  }
`;
