import React from 'react';
import { connect } from 'react-redux';
import { getMenuFields } from 'layouts/AppLayout/getMenuItems';
import Sidebar from './Sidebar';
import BodyNotification from './BodyNotification';

const Body = ({ children, ...props }) => {
  const fields = getMenuFields();
  return (
    <div className="dashboard__body__wrapper fl-row-justify-left">
      <div className="dashboard__body__wrapper__sidebar">
        <Sidebar menuItems={fields} />
      </div>
      <div className="dashboard__body__wrapper__content">
        <BodyNotification requireAccountDetails={props.requireAccountDetails} />
        {children}
      </div>
    </div>
  );
};

const mapState = ({ profileStore }) => ({
  requireAccountDetails: profileStore.profile_data?.update_account || false,
});

export default connect(mapState)(Body);
