/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import ReactSelect from 'react-select';
import PhoneNumberField from 'components/PhoneNumberField';
import MaskedFormField from '../MaskedFormField';

function getGeneralFields(obj = {}) {
  const {
    first_name,
    surname,
    email,
    contact_number,
    id_number,
    contact_number_country,
    whatsapp_number,
    whatsapp_number_country,
  } = obj;
  const country_code = contact_number_country?.code;
  const whatsapp_country_code = whatsapp_number_country?.code;
  return {
    first_name,
    surname,
    email,
    country_code,
    contact_number,
    id_number,
    whatsapp_number,
    whatsapp_country_code,
  };
}

function getOptionsFromGraphqlResponse(response) {
  return response?.map((res) => ({
    label: res?.communication_preference,
    value: res?.communication_preference,
  }));
}

function generateSelectedValue(option) {
  return {
    value: option,
    label: option,
  };
}

export default (
  values,
  diallingCodes,
  communicationPreferenceOptions,
  onPreferenceChange,
  communication_preference,
) => {
  const generalFields = values ? getGeneralFields(values) : {};

  const options = getOptionsFromGraphqlResponse(communicationPreferenceOptions);

  const default_value = generateSelectedValue(values?.communication_preference);
  const selected = communication_preference
    ? generateSelectedValue(communication_preference)
    : default_value;

  return [
    {
      fields: [
        {
          type: 'email',
          mandatory: true,
          name: 'email',
          label: 'Email',
          viewOnly: true,
          itemClass: 'form__item--horizontal',
          value: generalFields?.email || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'first_name',
          label: 'First name',
          itemClass: 'form__item--horizontal',
          value: generalFields?.first_name || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'surname',
          label: 'Surname',
          itemClass: 'form__item--horizontal',
          value: generalFields?.surname || '',
        },
        {
          type: 'component',
          mandatory: true,
          name: 'contact_number',
          label: 'Contact Number',
          value: '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal">
                <label htmlFor="contact_number" className="form__item__label">
                  Contact Number
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                <div className="text--dk--flushed form__item__input">
                  <PhoneNumberField
                    name="contact_number"
                    className="form__item--horizontal"
                    phoneNumber={generalFields?.contact_number}
                    countryCode={generalFields?.country_code}
                    countryCodeData={diallingCodes}
                    countryCodeVar="country_code"
                    phoneNumberVar="contact_number"
                    {...props}
                  />
                </div>
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: false,
          name: 'whatsapp_number',
          label: 'WhatsApp Number',
          value: '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal">
                <label htmlFor="whatsapp_number" className="form__item__label">
                  WhatsApp Number
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                <div className="text--dk--flushed form__item__input">
                  <PhoneNumberField
                    name="whatsapp_number"
                    className="form__item--horizontal"
                    // show contact number as whatsapp number if whatsapp number is not set
                    phoneNumber={
                      generalFields?.whatsapp_number
                        ? generalFields?.whatsapp_number
                        : generalFields?.contact_number
                    }
                    countryCode={
                      generalFields?.whatsapp_country_code
                        ? generalFields?.whatsapp_country_code
                        : generalFields?.country_code
                    }
                    countryCodeVar="whatsapp_country_code"
                    phoneNumberVar="whatsapp_number"
                    countryCodeData={diallingCodes}
                    placeholder="WhatsApp Number"
                    {...props}
                  />
                </div>
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: false,
          name: 'communication_preference',
          label: 'Communication Preference',
          value: generalFields?.contact_number || '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal hidden">
                <label
                  htmlFor="communication_preference"
                  className="form__item__label">
                  Communication Preference
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                {props?.viewOnly ? (
                  <div className="text--dk--flushed form__item__input">
                    <ReactSelect
                      isMulti={false}
                      isSearchable={false}
                      components={{ IndicatorSeparator: () => null }}
                      options={options}
                      className="notification-dropdown-select-container"
                      classNamePrefix="notification-dropdown-select"
                      onChange={() => {}}
                      isDisabled
                      value={[default_value]}
                    />
                  </div>
                ) : (
                  <div className="text--dk--flushed form__item__input">
                    <ReactSelect
                      isMulti={false}
                      isSearchable={false}
                      components={{ IndicatorSeparator: () => null }}
                      options={options}
                      className="notification-dropdown-select-container"
                      classNamePrefix="notification-dropdown-select"
                      onChange={onPreferenceChange}
                      value={[selected]}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: true,
          name: 'id_number',
          label: 'Identity Number',
          component: ({ ...props }) => (
            <MaskedFormField {...props} passedObj={generalFields} />
          ),
        },
      ],
    },
  ];
};
