import React, { Fragment } from 'react';
import api from 'constants/api';
import axios from 'helpers/axios';
import PrimaryButton from '@bonlineza/luxity-lib/PrimaryButton';
import RadioButtons from '@bonlineza/luxity-lib/RadioButtons';
import { getMessageFrom } from 'functions/responseMessages';

const OTPRequestMethods = {
  PHONE: 'phone',
  EMAIL: 'email',
};

class RequestOTP extends React.Component {
  state = { otp_request_method: null, form_error: null, isApiBusy: false };

  controlRef = (name, value) => {
    const { errorMessages } = this.state;
    const newErrorMessages = { ...errorMessages };
    delete newErrorMessages[name];
    this.setState({ [name]: value, form_error: null });
  };

  onSubmit = () => {
    const { otp_request_method } = this.state;
    const { country_code, phone_number, email } = this.props;
    let payload = {};
    if (otp_request_method === OTPRequestMethods.EMAIL) {
      payload = { email };
    } else if (otp_request_method === OTPRequestMethods.PHONE) {
      payload = { country_code, phone_number };
    } else {
      return;
    }

    this.setState({ isApiBusy: true });
    axios
      .post(api.user.REQUEST_OTP, payload)
      .then(() => {
        this.setState({ isApiBusy: false });
        this.props.actionCallback('otp_requested')();
      })
      .catch((e) => {
        if (e.status === 422) {
          this.setState({ form_error: getMessageFrom(e.data) });
        } else {
          this.props.actionCallback('generic_error')();
        }
        this.setState({ isApiBusy: false });
      });
  };

  render = () => {
    const { form_error, isApiBusy } = this.state;
    return (
      <Fragment>
        <RadioButtons
          noWrap
          name="otp_request_method"
          title="Send OTP"
          options={[
            {
              innerText: 'Mobile',
              value: OTPRequestMethods.PHONE,
              actionCallback: () =>
                this.controlRef('otp_request_method', OTPRequestMethods.PHONE),
            },
            {
              innerText: 'Email',
              value: OTPRequestMethods.EMAIL,
              actionCallback: () =>
                this.controlRef('otp_request_method', OTPRequestMethods.EMAIL),
            },
          ]}
        />
        {form_error ? <p>{form_error}</p> : null}
        <div className="fl-right">
          <div className="fl-right__item">
            <PrimaryButton
              disabled={isApiBusy}
              type="button"
              onClick={this.onSubmit}>
              {isApiBusy ? 'Busy...' : 'Reset Password'}
            </PrimaryButton>
          </div>
        </div>
      </Fragment>
    );
  };
}

export default RequestOTP;
