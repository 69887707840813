import '@bonlineza/b-lib/init';
import 'normalize.css';
import './css/style.scss';
import React, { Fragment } from 'react';
import { initGA } from 'components/Tracking';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import config from 'config/config.json';
import { initSentry } from 'functions/sentry';
import history from 'helpers/history';
import IsAuthed from 'layouts/IsAuthed';
import IsNotAuthed from 'layouts/IsNotAuthed';
import AppLayout from 'layouts/AppLayout';
import FullLayout from 'layouts/FullLayout';
import BasicLayout from 'layouts/BasicLayout';
import { registerWpResForNav } from 'functions/registerWpResForNav.func';

// VIEWS
import Login from 'views/Login';
import Review from 'views/Review';
import NotFoundPage from 'views/NotFoundPage';
import VoidSale from 'views/VoidSale';
import UnSubscribeInterest from 'views/UnSubscribeInterest';
import Dashboard from 'views/Dashboard';
import Orders from 'views/Orders';
import OrderHistory from 'views/OrderHistory';
import Sales from 'views/Sales';
import SalesHistory from 'views/SalesHistory';
import Profile from 'views/Profile';
import Payment from 'views/Payment';
import PaymentResult from 'views/PaymentResult';
import AddressesView from 'views/AddressesView';
import Interests from 'views/Interests';
import Coupons from 'views/Coupons';
import AutoAuth from 'views/AutoAuth/AutoAuth';

import store from './store';

initSentry();
registerWpResForNav();

initGA(config.GOOGLE_ANALYTICS_KEY);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppLayout>
        <Fragment>
          <IsAuthed>
            <Switch>
              <Route exact path="/review/:sale_id(\d+)/:template/:token">
                <BasicLayout>
                  <Review />
                </BasicLayout>
              </Route>
              <Route path="*">
                <FullLayout>
                  <Switch>
                    <Route exact path="/auto-auth">
                      <AutoAuth />
                    </Route>
                    <Route exact path="/">
                      <Dashboard />
                    </Route>
                    <Route exact path="/interests">
                      <Interests />
                    </Route>
                    <Route exact path="/orders">
                      <Orders />
                    </Route>
                    <Route exact path="/order-history">
                      <OrderHistory />
                    </Route>
                    <Route exact path="/sales">
                      <Sales />
                    </Route>
                    <Route exact path="/sales-history">
                      <SalesHistory />
                    </Route>
                    <Route exact path="/profile">
                      <Profile />
                    </Route>
                    <Route path="/payment/:order_number">
                      <Payment />
                    </Route>
                    <Route path="/payment-result/:status">
                      <PaymentResult />
                    </Route>
                    <Route path="/addresses">
                      <AddressesView />
                    </Route>
                    <Route exact path="/order/:sale_id(\d+)/void/:token">
                      <VoidSale />
                    </Route>
                    <Route exact path="/interests/unsubscribe/:token">
                      <UnSubscribeInterest />
                    </Route>
                    <Route path="/coupons">
                      <Coupons />
                    </Route>
                    <Route path="*">
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </FullLayout>
              </Route>
            </Switch>
          </IsAuthed>
          <IsNotAuthed>
            <BasicLayout>
              <Switch>
                <Route exact path="/auto-auth">
                  <AutoAuth />
                </Route>
                <Route exact path="/">
                  <Login />
                </Route>
                <Route exact path="/review/:sale_id(\d+)/:template/:token">
                  <Review />
                </Route>
                <Route exact path="/order/:sale_id(\d+)/void/:token">
                  <VoidSale />
                </Route>
                <Route exact path="/interests/unsubscribe/:token">
                  <UnSubscribeInterest />
                </Route>
                <Route exact path="/payment-result/:status">
                  <PaymentResult />
                </Route>
                <Route path="/payment/:order_number">
                  <Login systemMessage="Please login first to reach the payment page" />
                </Route>
                <Route path="*">
                  <Login />
                </Route>
              </Switch>
            </BasicLayout>
          </IsNotAuthed>
        </Fragment>
      </AppLayout>
    </ConnectedRouter>
  </Provider>,

  document.querySelector('#root'),
);

export default store;
