import React, { useState } from 'react';
import api from 'constants/api';
import getHTML from 'functions/getHTML.func';
import LuxityLoader from 'components/LuxityLoader';
import { connect } from 'react-redux';
import { getMobileMenuFields } from 'layouts/AppLayout/getMenuItems';
import LuxityMobileSideMenu from 'components/LuxityMobileMenu';
import GetSvg from 'vendor/GetSvg';
import $ from 'jquery';
import withRouter from 'helpers/withRouter';

const disableBodyScroll = () => {
  $('html, body').css({
    overflow: 'hidden',
    height: '100%',
  });
};

const enableBodyScroll = () => {
  $('html, body').css({
    overflow: 'auto',
    height: 'auto',
  });
};

const LuxityMenu = ({ history, isAuth }) => {
  const menuRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMenuMobileActive, setMobileMenuState] = useState(false);

  const openMenu = () => {
    disableBodyScroll();
    setMobileMenuState(true);
  };
  const closeMenu = () => {
    enableBodyScroll();
    setMobileMenuState(false);
  };

  const navigateToPage = (route) => {
    closeMenu();
    history.push(route);
  };

  const initProductSearchHandle = () => {
    /**
     * the loading of initCustomMenu is asyncrously because external js request
     * syntax handles if method is not defined
     * * */
    let attempts = 0;
    function startProcess() {
      if (attempts < 5) {
        try {
          const productSearchHandle = () => initCustomMenu($);
          productSearchHandle();
        } catch (e) {
          if (e instanceof ReferenceError) {
            attempts += 1;
            window.setTimeout(() => {
              startProcess();
            }, 500);
          }
        }
      }
    }
    startProcess();
  };

  const menuFields = getMobileMenuFields(navigateToPage);
  const handleGetNavHtml = React.useCallback(() => {
    setIsLoading(true);
    getHTML(api.getWPMenuHTML(), (luxityHTML) => {
      setIsLoading(false);
      const wpElement = luxityHTML.querySelector('.custom-navbar');
      if (wpElement) {
        const wpImageElements = wpElement.querySelectorAll('img');
        Array.from(wpImageElements).forEach((imgElement) => {
          if (imgElement.hasAttribute('data-lazy-src')) {
            imgElement.setAttribute('src', imgElement.dataset.lazySrc);
          }
        });
        wpElement.querySelector('.actions-wrapper__my-account-link').remove();
        menuRef.current.appendChild(wpElement);
        initProductSearchHandle();
      }
    });
  }, []);

  React.useEffect(() => {
    handleGetNavHtml();
  }, [handleGetNavHtml]);

  const getContainerModifier = () => {
    const baseContainerClass = 'luxity-wordpress-menu-container';
    if (isLoading) {
      return `${baseContainerClass}--loading`;
    }
    return isAuth ? `${baseContainerClass}--authed` : baseContainerClass;
  };

  return (
    <React.Fragment>
      <div className={getContainerModifier()}>
        <div ref={menuRef} />

        {isLoading ? (
          <LuxityLoader />
        ) : (
          <button
            type="button"
            onClick={() => openMenu()}
            className="luxity-wordpress-menu-container__auth-burger-menu">
            <GetSvg svg="luxity_menu" wrapperClass="svg" />
          </button>
        )}
      </div>
      <LuxityMobileSideMenu
        navigateToPage={navigateToPage}
        isMenuMobileActive={isMenuMobileActive}
        closeMenu={closeMenu}
        fields={menuFields}
      />
    </React.Fragment>
  );
};
const mapState = ({ authStore }) => ({
  isAuth: authStore.auth,
});

export default connect(mapState)(withRouter(LuxityMenu));
