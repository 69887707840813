import React, { Fragment, useState, useEffect } from 'react';
import SimpleSelect from 'react-select';
import { fC } from 'helpers/locale';
import StatusItemGauge from 'components/StatusItemGauge';
import convertUnixTimeToDate from 'functions/convertUnixTimeToDate.func';
import GALink from 'components/GALink';
import GARoute from 'components/GARoute';
import { SimplePopup } from '@bonlineza/b-lib';
import { TextButton } from '@bonlineza/luxity-lib';
import api from 'constants/api';
import axios from 'helpers/axios';
import {
  error as notifyError,
  success as notifySuccess,
} from '@bonlineza/luxity-lib/Notify';
import { hasValue } from '@bonlineza/b-lib/functions';
import { carrierTypeConstants } from 'constants/carrierTypes';
import requestErrorMessages from 'constants/requestErrorMessages';
import LuxityLoader from 'components/LuxityLoader';
import PropTypes from 'prop-types';
import purchaseStatuses from 'constants/purchaseStatuses';
import GetSvg from 'vendor/GetSvg';
import CancellationModal from './CancelOrderConfirmationModal';

const FullDetailsItem = ({ data, onOrderChanged }) => {
  const [isLoading, setIsLoadingState] = useState(false);
  const [isCarrierModalVisible, setCarrierModalVisibility] = useState(false);
  const [carrierType, setCarrierType] = useState('');
  const [carrierErrMsg, setCarrierErrMsg] = useState('');
  const [addressId, setAddressId] = useState('');
  const [addressErrMsg, setAddressErrMsg] = useState('');

  useEffect(() => {
    // clear error state and value stored
    if (carrierType !== carrierTypeConstants.FREE_DELIVERY) {
      setAddressErrMsg('');
      setAddressId('');
    }
  }, [carrierType]);

  const clearAddress = () => {
    setAddressErrMsg('');
    setAddressId('');
  };

  const clearCarrier = () => {
    setCarrierErrMsg('');
    setCarrierType('');
  };

  const closeCarrierModal = () => {
    // clear state
    clearAddress();
    clearCarrier();
    setCarrierModalVisibility(false);
  };

  const formatCarrierOptionForSelect = (options) =>
    options.map((option) => ({ label: option, value: option }));

  const formatShippingOptionsForSelect = (options) =>
    options.map(({ line1, line2, id }) => ({
      label: `${hasValue(line1) ? line1 : ''} ${
        hasValue(line2) ? `, ${line2}` : ''
      }`,
      value: id,
    }));

  const handleCarrierChange = (selectedOption) => {
    setCarrierErrMsg('');
    if (selectedOption && selectedOption.value !== undefined) {
      setCarrierType(selectedOption.value);
    }
  };

  const handleAddressChange = (selectedOption) => {
    setAddressErrMsg('');
    if (selectedOption && selectedOption.value !== undefined) {
      setAddressId(selectedOption.value);
    }
  };

  const isInputValid = () => {
    if (!carrierType) {
      setCarrierErrMsg('Please select a carrier type');
      return false;
    }
    if (carrierType === 'Free Delivery' && !hasValue(addressId)) {
      setAddressErrMsg('Please select a shipping address');
      return false;
    }
    return true;
  };

  const updateCarrierType = () => {
    if (isInputValid()) {
      setIsLoadingState(true);
      axios
        .post(`${api.user.UPDATE_CARRIER_TYPE}/${data.id}/update/carrier`, {
          carrier: carrierType,
          ...(carrierType === carrierTypeConstants.FREE_DELIVERY
            ? { shipping_address_id: addressId }
            : {}),
        })
        .then(() => {
          closeCarrierModal();
          notifySuccess('Carrier type updated successfully');
          setTimeout(() => {
            onOrderChanged();
          }, 500);
          setIsLoadingState(false);
        })
        .catch((e) => {
          // Clear input then set errors
          clearAddress();
          clearCarrier();
          setIsLoadingState(false);
          handleError(e.status, e.data);
        });
    }
  };

  const handleError = (code, errorData = {}) => {
    if (code === 422) {
      if (errorData.carrier) {
        return setCarrierErrMsg(errorData.carrier[0]);
      }
      if (errorData.shipping_address_id) {
        return setAddressErrMsg(errorData.shipping_address_id[0]);
      }
      return false;
    }
    setCarrierModalVisibility(false);
    return notifyError(requestErrorMessages.RESPONSE_500);
  };

  const isCarrierButtonDisabled = (addresses = []) => {
    if (isLoading) {
      return true;
    }

    return (
      addresses.length === 0 &&
      carrierType === carrierTypeConstants.FREE_DELIVERY
    );
  };

  const getOutstandingOrCancelFeeContent = (
    outstanding_balance = 0,
    cancellation_fee = 0,
  ) => {
    if (cancellation_fee > 0) {
      return (
        <div className="item__summary__item">
          <div className="item__summary__item__left">
            <strong>CANCELLATION FEE</strong>
          </div>
          <div className="item__summary__item__right fc--primary">
            {fC(cancellation_fee, false)}
          </div>
        </div>
      );
    }
    if (outstanding_balance > 0) {
      return (
        <div className="item__summary__item">
          <div className="item__summary__item__left">
            <strong>OUTSTANDING BALANCE</strong>
          </div>
          <div className="item__summary__item__right fc--primary">
            {fC(outstanding_balance, false)}
          </div>
        </div>
      );
    }

    return null;
  };

  const [isVoidModalVisible, setVoidModalState] = useState(false);
  const openVoidModal = () => setVoidModalState(true);

  return (
    <Fragment>
      <div className="orders__item item">
        <div className="item__header">
          <div className="item__header__left">
            <div className="item__header__left__primary">
              {` Order No. ${data.reference}`}
            </div>
            <div className="item__header__left__secondary">
              <div>
                Order Date:&nbsp;
                <span className="fc--primary">
                  {(data.date && convertUnixTimeToDate(data.date)) ||
                    'No date set'}
                </span>
              </div>
              {data.due_date && (
                <div className="u-text--bold">
                  Due Date:&nbsp;
                  <span className="fc--primary">
                    {convertUnixTimeToDate(data.due_date)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="item__header__right">
            <div className="item__header__right__primary">
              Order Status:&nbsp;
              <span className="fc--primary">{data.status.text}</span>
            </div>
            <StatusItemGauge
              statusList={data.status.list}
              statusPosition={data.status.index}
            />
          </div>
        </div>
        <div className="orders__item__details item__details">
          <div className="item__details__sub-items sub-items">
            {data.items.map((item, key) => (
              <div className="sub-items__item" key={key}>
                <div className="sub-items__item__image">
                  <img src={item.image} alt="item.name" />
                </div>
                <div className="sub-items__item__details">
                  <div className="sub-items__item__details__item">
                    {item.name}
                  </div>
                  <div className="sub-items__item__details__item">
                    Item Price:&nbsp;
                    <strong>{fC(item.price, false)}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="orders__item__summary item__summary">
          <Fragment>
            <div className="item__summary__item">
              <div className="item__summary__item__left">
                Collection/Delivery:
              </div>
              <div className="item__summary__item__right">
                <div>
                  {data.type || 'No carrier set'}
                  {data?.type === carrierTypeConstants.FREE_DELIVERY && (
                    <Fragment>
                      <span>,&nbsp;</span>
                      <span>
                        {data?.shipping_address?.display_address_line1 || ''}
                      </span>
                    </Fragment>
                  )}
                </div>

                {!data.is_shipped && (
                  <div className="u-margin--small--top">
                    <TextButton
                      type="button"
                      onClick={() => setCarrierModalVisibility(true)}>
                      [Edit Address]
                    </TextButton>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
          {data.shipping_method && (
            <div className="item__summary__item">
              <div className="item__summary__item__left">Shipping Method:</div>
              <div className="item__summary__item__right">
                {data.shipping_method}
              </div>
            </div>
          )}
          {data.tracking_number && (
            <div className="item__summary__item">
              <div className="item__summary__item__left">Tracking Number:</div>
              <div className="item__summary__item__right">
                {data.tracking_number}
              </div>
            </div>
          )}
          {data.location && (
            <div className="item__summary__item">
              <div className="item__summary__item__left">Location:</div>
              <div className="item__summary__item__right">{data.location}</div>
            </div>
          )}
          <div className="item__summary__item">
            <div className="item__summary__item__left">
              <strong>Sub Total</strong>
            </div>
            <div
              className={`item__summary__item__right ${
                !data.outstanding_balance ? 'fc--primary' : ''
              }`}>
              {fC(data.sub_total, false)}
            </div>
          </div>
          <div className="item__summary__item">
            <div className="item__summary__item__left">
              <strong>Coupon</strong>
            </div>
            <div
              className={`item__summary__item__right ${
                !data.outstanding_balance ? 'fc--primary' : ''
              }`}>
              {fC(data.coupon_total, false)}
            </div>
          </div>

          <div className="item__summary__item">
            <div className="item__summary__item__left">
              <strong>ORDER TOTAL</strong>
            </div>
            <div
              className={`item__summary__item__right ${
                !data.outstanding_balance ? 'fc--primary' : ''
              }`}>
              {fC(data.total, false)}
            </div>
          </div>

          {getOutstandingOrCancelFeeContent(
            data.outstanding_balance,
            data.cancellation_fee,
          )}

          <div className="item-summary-desktop-container">
            <div className="item__summary__item--payment-row">
              <div className="item__summary__item__left">
                {data.latest_invoice_url && (
                  <Fragment>
                    <div className="btn--icon--left inv-download">
                      <GetSvg svg="download" wrapperClass="svg" />
                      <GALink
                        href={data.latest_invoice_url}
                        label="Download Latest Invoice"
                        target="_blank"
                        rel="noopener noreferrer">
                        Download Latest Invoice
                      </GALink>
                    </div>
                  </Fragment>
                )}
                {data.status.text === purchaseStatuses.awaiting_payment && (
                  <div className="btn--icon--left">
                    <GetSvg svg="cancel_order" wrapperClass="svg" />
                    <button
                      type="button"
                      className="cancel-order-btn"
                      data-qe-id="current-order-cancel-sale"
                      onClick={() => openVoidModal()}>
                      Cancel Order
                    </button>
                    {isVoidModalVisible && (
                      <CancellationModal
                        isOpen={isVoidModalVisible}
                        close={setVoidModalState}
                        data={data}
                      />
                    )}
                  </div>
                )}
              </div>
              {data.outstanding_balance && data.outstanding_balance > 0 ? (
                <div className="item__summary__item__right orders__item__payment__button">
                  <div>
                    <GARoute
                      to={`/payment/${data.id}`}
                      className="btn--luxity"
                      label="MAKE PAYMENT">
                      MAKE PAYMENT
                    </GARoute>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="item-summary-mobile-container">
            <div className="item__summary__item--payment-row">
              <div className="item__summary__item__left">
                {data.latest_invoice_url && (
                  <Fragment>
                    <div className="btn--icon--left inv-download">
                      <GetSvg svg="download" wrapperClass="svg" />
                      <GALink
                        href={data.latest_invoice_url}
                        label="Download Latest Invoice"
                        target="_blank"
                        rel="noopener noreferrer">
                        Download Latest Invoice
                      </GALink>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="item__summary__mobile__payment">
              <div className="item__summary__item__left">
                {data.status.text === purchaseStatuses.awaiting_payment && (
                  <div className="btn--icon--left">
                    <GetSvg svg="cancel_order" wrapperClass="svg" />
                    <button
                      type="button"
                      className="cancel-order-btn"
                      data-qe-id="current-order-cancel-sale"
                      onClick={() => openVoidModal()}>
                      Cancel Order
                    </button>
                    {isVoidModalVisible && (
                      <CancellationModal
                        isOpen={isVoidModalVisible}
                        close={setVoidModalState}
                        data={data}
                      />
                    )}
                  </div>
                )}
              </div>
              {data.outstanding_balance && data.outstanding_balance > 0 ? (
                <div className="item__summary__item__right orders__item__payment__button">
                  <div>
                    <GARoute
                      to={`/payment/${data.id}`}
                      className="btn--luxity"
                      label="MAKE PAYMENT">
                      MAKE PAYMENT
                    </GARoute>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="orders__item__popup-wrapper">
          <SimplePopup
            isOpen={isCarrierModalVisible}
            title="Edit Carrier Details"
            cannotOutsideClickClose
            showLoader={isLoading}
            renderLoader={() => <LuxityLoader />}
            close={closeCarrierModal}
            options={[
              {
                cb: () => {
                  closeCarrierModal();
                },
                buttonText: 'Close',
                disabled: isLoading,
                buttonClass:
                  'modal__container__footer__item__button--secondary-button',
                dataQeId: 'button--carrier--cancel',
              },
              {
                cb: () => updateCarrierType(),
                buttonText: 'Update Carrier',
                disabled: isCarrierButtonDisabled(data.customer_addresses),
                buttonClass:
                  'modal__container__footer__item__button--primary-button--bold',
                dataQeId: 'button--carrier--update',
              },
            ]}
            renderContent={() => (
              <div className="u-padding">
                {data.carriers.length > 0 ? (
                  <Fragment>
                    <div>
                      Please select carrier type
                      <SimpleSelect
                        placeholder="Carrier Type"
                        onChange={handleCarrierChange}
                        options={formatCarrierOptionForSelect(
                          data.carriers || [],
                        )}
                      />
                      {carrierErrMsg && (
                        <div className="u-fc--primary">{carrierErrMsg}</div>
                      )}
                      {carrierType === carrierTypeConstants.FREE_DELIVERY && (
                        <div className="u-margin--top">
                          {data?.customer_addresses.length > 0 ? (
                            <Fragment>
                              <div>
                                Choose a shipping address(to add shipping
                                address to&nbsp;
                                <GARoute to="/addresses" label="My Addresses">
                                  My Addresses
                                </GARoute>
                              </div>
                              <div>
                                <SimpleSelect
                                  placeholder="Shipping Address"
                                  onChange={handleAddressChange}
                                  options={formatShippingOptionsForSelect(
                                    data.customer_addresses || [],
                                  )}
                                />
                              </div>

                              {addressErrMsg && (
                                <div className="u-fc--primary">
                                  {addressErrMsg}
                                </div>
                              )}
                            </Fragment>
                          ) : (
                            <div>
                              You have no shipping addresses. Click
                              <GARoute to="/profile" label="profile">
                                <span className="u-fc--primary"> here </span>
                              </GARoute>
                              to add a shipping address
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <div>No carrier options available</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

FullDetailsItem.propTypes = {
  onOrderChanged: PropTypes.func.isRequired,
  data: PropTypes.shape({
    order_number: PropTypes.string.isRequired,
    order_date: PropTypes.string.isRequired,
    order_status: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    order_subtotal: PropTypes.number.isRequired,
    order_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shipping_method: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    location: PropTypes.string.isRequired,
    tracking_number: PropTypes.string.isRequired,
    outstanding_balance: PropTypes.number.isRequired,
    payment_method: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_total: PropTypes.number.isRequired,
    invoices: PropTypes.array,
  }).isRequired,
};

export default FullDetailsItem;
