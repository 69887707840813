export const getSalesListQuery = (initialLimit = 5) =>
  `{
        client_purchase_list(limit: ${initialLimit}, page: 1, returned: false, luxity_purchase_status: current){
            data{
                id
                order_number
                luxity_purchase_status
                estimated_payout_date
                purchase_order{
                    total
                    purchase_order_lines{
                        product{
                            image
                        }
                    }
                }
            }
            per_page
            total
            current_page
            last_page
            from
        }
    }`;
