import React, { Fragment, useRef, useState, useEffect } from 'react';
import api from 'constants/api';
import IconHeader from 'components/IconHeader';
import SalesComponent from 'components/Sales/FullDetailsItem';
import GARoute from 'components/GARoute';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import scrollToElement from 'functions/scrollToElement';
import withRouter from 'helpers/withRouter';
import NoSale from '../../components/NoSale';

const CurrentSalesView = (props) => {
  const ordersPageReady = useRef();
  const [targetSaleId, setTargetSaleId] = useState('');
  const updateSalesPage = () => ordersPageReady.current.refreshPage();
  useEffect(() => {
    if (targetSaleId) {
      window.setTimeout(() => {
        const targetElement = document.getElementById(targetSaleId);
        scrollToElement(targetElement);
      }, 500);
    }
  }, [targetSaleId]);

  return (
    <Fragment>
      <IconHeader title="Current Sale" svgName="my_sale" />
      <AxiosPageReadyWrapper
        ref={ordersPageReady}
        url={api.data.CURRENT_SALES}
        onData={() => setTargetSaleId(props.location.target_id)}>
        <AxiosPageReadyContext.Consumer>
          {({ data: current_sales = [] }) => {
            if (current_sales.length > 0) {
              return current_sales.map((sale, index) => (
                <div id={sale.id} key={index}>
                  <SalesComponent
                    data={sale}
                    onSaleChanged={updateSalesPage}
                    footer={() => (
                      <GARoute
                        className="u-text--bold"
                        to="/sale-history"
                        label="View Past Sales">
                        View Past Sales
                      </GARoute>
                    )}
                  />
                </div>
              ));
            }
            return <NoSale />;
          }}
        </AxiosPageReadyContext.Consumer>
      </AxiosPageReadyWrapper>
    </Fragment>
  );
};

export default withRouter(CurrentSalesView);
