import React, { Fragment } from 'react';
import config from 'config/config.json';
import GALink from 'components/GALink';

export default function NoSale() {
  return (
    <Fragment>
      <div className="sales__item item u-text--center">
        <h6>You haven't sold to Luxity yet. See what we can offer you.</h6>
        <div className="u-margin--bottom">
          <GALink
            href={`${config.LUXITY_SHOP_URL}/sell-to-us`}
            className="btn--luxity"
            label="Sell To Us"
            target="_blank"
            rel="noopener noreferrer">
            Sell To Us
          </GALink>
        </div>
      </div>
    </Fragment>
  );
}
