/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import config from 'config/config.json';
import GALink from 'components/GALink';
import GetSvg from 'vendor/GetSvg';

export default () => {
  const currentYear = new Date().getFullYear();
  return (
    <Fragment>
      <div className="footer-background">
        <div className="wrap--largest footer-wrap">
          <div className="footer-container-wrapper">
            <div className="footer-container">
              <div>
                <GetSvg svg="luxity-logo" wrapperClass="footer-luxity-logo" />
              </div>
              <div>
                <h4>BUY</h4>
                <div className="footer-link-container">
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/brands`}
                    label="New"
                    rel="noopener noreferrer"
                    target="_blank">
                    New
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/handbags-purses`}
                    label="Bags"
                    rel="noopener noreferrer"
                    target="_blank">
                    Bags
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/shoes`}
                    label="Shoes"
                    rel="noopener noreferrer"
                    target="_blank">
                    Shoes
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/apparel/brands`}
                    label="Clothing"
                    rel="noopener noreferrer"
                    target="_blank">
                    Clothing
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/accessories`}
                    label="Accessories"
                    rel="noopener noreferrer"
                    target="_blank">
                    Accessories
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/product-category/watches`}
                    label="Watches"
                    rel="noopener noreferrer"
                    target="_blank">
                    Watches
                  </GALink>
                </div>
              </div>

              <div>
                <div>
                  <h4>SELL</h4>
                  <div className="footer-link-container">
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/sell-to-us`}
                      label="Sell To Us"
                      rel="noopener noreferrer"
                      target="_blank">
                      Sell To Us
                    </GALink>
                  </div>
                </div>

                <div className="footer-company">
                  <h4>COMPANY</h4>
                  <div className="footer-link-container">
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/about`}
                      label="Our Story"
                      rel="noopener noreferrer"
                      target="_blank">
                      Our Story
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/blog`}
                      label="Blog"
                      rel="noopener noreferrer"
                      target="_blank">
                      Blog
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/careers`}
                      label="Careers"
                      rel="noopener noreferrer"
                      target="_blank">
                      Careers
                    </GALink>
                  </div>
                </div>
              </div>

              <div>
                <h4>HELP</h4>
                <div className="footer-link-container">
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/faq`}
                    label="FAQ's"
                    rel="noopener noreferrer"
                    target="_blank">
                    FAQs
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/shipping`}
                    label="Shipping and Returns"
                    rel="noopener noreferrer"
                    target="_blank">
                    Shipping &amp; Returns
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/terms-conditions`}
                    label="Terms and Conditions"
                    rel="noopener noreferrer"
                    target="_blank">
                    Terms &amp; Conditions
                  </GALink>
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/privacy`}
                    label="Privacy Policy"
                    rel="noopener noreferrer"
                    target="_blank">
                    Privacy Policy
                  </GALink>
                </div>
              </div>
              <div>
                <h4>FOLLOW US</h4>
                <div className="footer-social-container">
                  <GALink
                    href="https://www.facebook.com/luxity.za"
                    label="Facebook"
                    className="social"
                    rel="noopener noreferrer"
                    target="_blank">
                    <i className="fa fa-facebook" />
                    Facebook
                  </GALink>
                  <GALink
                    href="https://www.instagram.com/luxity.za/"
                    label="Instagram"
                    className="social"
                    rel="noopener noreferrer"
                    target="_blank">
                    <i className="fa fa-instagram" />
                    Instagram
                  </GALink>
                  <GALink
                    href="https://twitter.com/luxityZA"
                    label="Twitter"
                    className="social"
                    rel="noopener noreferrer"
                    target="_blank">
                    <i className="fa fa-twitter" />
                    Twitter
                  </GALink>
                  <GALink
                    href="https://za.pinterest.com/luxityZA/"
                    label="Pinterest"
                    className="social"
                    rel="noopener noreferrer"
                    target="_blank">
                    <i className="fa fa-pinterest-p" />
                    Pinterest
                  </GALink>
                </div>
              </div>
              <div>
                <h4>CONTACT</h4>
                <div className="footer-link-container">
                  <GALink
                    href={`${config.LUXITY_SHOP_URL}/contact-us`}
                    label="Contact Us"
                    rel="noopener noreferrer"
                    target="_blank">
                    Contact Us
                  </GALink>
                  <GALink
                    href="mailto:myorder@luxity.co.za"
                    label="Mail"
                    rel="noopener noreferrer"
                    target="_blank">
                    myorder@luxity.co.za
                  </GALink>
                  <div className="footer-phone">010 020 6171</div>
                </div>
                <div className="contact-office-hours">
                  <ul>
                    <li>Monday - Friday</li>
                    <li>8:30 am to 5:00 pm</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer-copyrights">
              <div>
                <img
                  alt="mastercard"
                  src="/assets/images/footer/mastercard.png"
                />
                <img alt="visa" src="/assets/images/footer/visa.png" />
              </div>
              <div>
                <p>
                  Copyright {currentYear} | Luxity (Pty) LTD | All Rights
                  Reserved | Website Developed by B Online
                </p>
              </div>
            </div>
          </div>

          <div className="mobile-footer-wrapper">
            <div className="mobile-footer-container">
              <div className="mobile-footer-container-row">
                <div className="mobile-footer-section">
                  <h4>QUICKIES</h4>
                  <div>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/products`}
                      label="Shop"
                      rel="noopener noreferrer"
                      target="_blank">
                      Shop
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/blog`}
                      label="Blog"
                      rel="noopener noreferrer"
                      target="_blank">
                      Blog
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/contact-us`}
                      label="Contact Us"
                      rel="noopener noreferrer"
                      target="_blank">
                      Contact
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/careers`}
                      label="Careers"
                      rel="noopener noreferrer"
                      target="_blank">
                      Careers
                    </GALink>
                  </div>
                </div>
                <div className="mobile-footer-section">
                  <h4>FOLLOW US</h4>
                  <div className="footer-social-container">
                    <GALink
                      href="https://www.facebook.com/shopluxity?fref=ts"
                      label="Facebook"
                      className="social"
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fa fa-facebook" />
                      Facebook
                    </GALink>
                    <GALink
                      href="https://instagram.com/shopluxity/"
                      label="Instagram"
                      className="social"
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fa fa-instagram" />
                      Instagram
                    </GALink>
                    <GALink
                      href="https://twitter.com/shopLUXITY"
                      label="Twitter"
                      className="social"
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fa fa-twitter" />
                      Twitter
                    </GALink>
                    <GALink
                      href="https://www.pinterest.com/shopLUXITY/"
                      label="Pinterest"
                      className="social"
                      rel="noopener noreferrer"
                      target="_blank">
                      <i className="fa fa-pinterest-p" />
                      Pinterest
                    </GALink>
                  </div>
                </div>
              </div>

              <div className="mobile-footer-container-row">
                <div className="mobile-footer-section">
                  <h4>WE LOVE...</h4>
                  <div className="footer-payment-options">
                    <p>Visa</p>
                    <p>MasterCard</p>
                    <p>EFT</p>
                  </div>
                </div>
                <div className="mobile-footer-section">
                  <h4>LEGAL STUFF</h4>
                  <div className="footer-link-container">
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/faq`}
                      label="FAQ's"
                      rel="noopener noreferrer"
                      target="_blank">
                      FAQs
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/terms-conditions`}
                      label="Terms and Conditions"
                      rel="noopener noreferrer"
                      target="_blank">
                      Terms &amp; Conditions
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/privacy`}
                      label="Privacy Policy"
                      rel="noopener noreferrer"
                      target="_blank">
                      Privacy Policy
                    </GALink>
                    <GALink
                      href={`${config.LUXITY_SHOP_URL}/shipping`}
                      label="Shipping and Returns"
                      rel="noopener noreferrer"
                      target="_blank">
                      Shipping &amp; Returns
                    </GALink>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-copyrights">
              <p>
                Copyright {currentYear} | Luxity (Pty) LTD | All Rights Reserved
                | Website Developed by B Online
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
