/** @format */

import React, { Component, Fragment } from 'react';

class MaskedFormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: props.hasError,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.errors &&
      Object.keys(nextProps.errors).includes(this.props.name) &&
      !this.state.hasError
    ) {
      this.setError(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewOnly && !this.props.viewOnly) {
      this.props.controlRef(this.props.name, this.props.value);
    }
  }

  setError = (nextVal = false) =>
    this.setState((prevState) => ({
      ...prevState,
      hasError: nextVal,
    }));

  changeHandler = (newVal) => {
    if (this.state.hasError) {
      this.setError();
    }
    this.props.controlRef(this.props.name, newVal);

    return newVal;
  };

  render = () => (
    <Fragment>
      <div
        className={`${
          (!this.props.viewOnly && this.state.hasError && `has-error `) || ''
        }
            ${this.props.itemClass}`}>
        <div className="form__item--horizontal">
          <label htmlFor={this.props.name} className="form__item__label">
            {this.props.label}
            {!this.props.viewOnly && this.props.mandatory && '*'}
          </label>
          {this.props.viewOnly ? (
            <div className="text--dk--flushed form__item__input">
              <div className={`form_item_${this.props.name}`}>
                {this.props.passedObj[this.props.name] || ''}
              </div>
            </div>
          ) : (
            <input
              data-qe-id={`action-input-${this.props.name}_field`}
              type="text"
              name={this.props.name}
              className="form__item__input"
              placeholder={this.props.passedObj[this.props.name] || ''}
              onChange={(e) => this.changeHandler(e.target.value)}
              defaultValue=""
            />
          )}
          {!this.props.viewOnly && this.state.hasError ? (
            <div className="form__item__validation">
              {this.props.errors[this.props.name]}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default MaskedFormField;
