import React, { Fragment } from 'react';
import MobileMenu from 'components/MobileMenu';
import GALink from 'components/GALink';
import GARoute from 'components/GARoute';
import GAButton from 'components/GAButton';
import { hasAuth, logoutCompletely } from 'functions/authenticator.func';
import config from 'config/config';
import GetSvg from 'vendor/GetSvg';

const getFooterContent = () =>
  hasAuth() ? (
    <GALink
      className="nav-sell-to-us-btn"
      href={config.LUXITY_SHOP_URL}
      target="_blank"
      rel="noopener noreferrer"
      label="Luxity Shop">
      Luxity Shop
    </GALink>
  ) : null;

const generateFields = (fields = []) =>
  fields.map((item, index) => (
    <Fragment key={`side-menu-${index}}`}>
      <div className={item.titleClassName}>
        <GetSvg
          svg={item.titleSvgName}
          wrapperClass={item.titleSvgWrapperClassName}
        />
        {item.titleInnerText}
      </div>
      {item.subFields.map((subItem, subIndex) => (
        <div className="side-nav-menu-item" key={subIndex}>
          <GAButton
            className={subItem.className}
            label={subItem.innerText}
            onClick={subItem.callbackFn}>
            <label htmlFor="_">{subItem.innerText}</label>
          </GAButton>
        </div>
      ))}
    </Fragment>
  ));

const LuxityMobileSideMenu = ({
  closeMenu = () => null,
  isMenuMobileActive = false,
  fields = [],
  navigateToPage = () => null,
}) => (
  <div className="side-nav luxity-mobile-side-menu">
    <MobileMenu isOpen={isMenuMobileActive} closeAction={() => closeMenu()}>
      <div className="luxity-mobile-side-menu__content-section">
        {generateFields(fields)}
      </div>
      <div className="luxity-mobile-side-menu__content-section">
        <div className="side-nav-menu-item">{getFooterContent()}</div>
      </div>
      <div className="luxity-mobile-side-menu__content-section">
        <div className="side-nav-menu-item u-margin--small--bottom">
          <GARoute label="Dashboard" to="/" onClick={() => navigateToPage('/')}>
            Dashboard
          </GARoute>
        </div>
        <div className="side-nav-menu-item u-margin--small--bottom">
          <GARoute
            isExternal
            label="Help"
            to={`${config.LUXITY_SHOP_URL}/bug-report/`}
            route={`${config.LUXITY_SHOP_URL}/bug-report/`}>
            Help
          </GARoute>
        </div>
        <div className="side-nav-menu-item u-margin--small--bottom">
          <GAButton
            label="Logout"
            onClick={() => {
              logoutCompletely();
            }}>
            Logout
          </GAButton>
        </div>
      </div>
    </MobileMenu>
  </div>
);

export default LuxityMobileSideMenu;
