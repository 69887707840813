import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ListPaginator = ({ showMore }) => {
  return (
    <Fragment>
      <div className="show-more-btn-container">
        <button
          type="button"
          className="btn--base show-more-btn"
          onClick={showMore}>
          <strong>Show More</strong>
        </button>
      </div>
    </Fragment>
  );
};

ListPaginator.propTypes = {
  showMore: PropTypes.func.isRequired,
};

export default ListPaginator;
