import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import api from 'constants/api';
import ListContext from './config/ListContext';
import QueryList from './components/QueryList';
import ListPaginator from './components/Paginator';

const GqlQueryList = ({
  gqlQueryFn,
  url,
  customErrorMessage,
  children,
  listKeyName,
  showMoreIncrementAmount,
}) => {
  const [listState, setListState] = useState([]);
  const [gqlQueryState, setGqlQueryState] = useState(() => gqlQueryFn());
  const [totalItems, setTotalItems] = useState();
  const [showMoreButton, setShowMoreButton] = useState(true);

  const renderPaginator = () => {
    if (showMoreButton && totalItems > showMoreIncrementAmount) {
      return <ListPaginator showMore={handleShowMore} />;
    }
    return null;
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    if (!(totalItems > showMoreIncrementAmount)) return;
    setShowMoreButton(false);
    const newGql = gqlQueryState.replace(
      `limit: ${showMoreIncrementAmount}`,
      `limit: ${totalItems}`,
    );
    updateGqlQuery(newGql);
  };

  // This is a useCallback with useEffect to ensure the dependencies
  // are always up to date when function is used
  const updateGqlQuery = useCallback((newGql) => {
    setGqlQueryState(newGql);
  }, []);

  useEffect(() => {
    updateGqlQuery(gqlQueryState);
  }, [gqlQueryState, updateGqlQuery]);

  return (
    <ListContext.Provider
      value={{
        list: listState,
      }}>
      <Fragment>
        <QueryList
          body={{ query: gqlQueryState }}
          url={url}
          customErrorMessage={customErrorMessage}
          onData={(dataObj) => {
            const listObj = dataObj[listKeyName];
            const { data: list } = listObj;
            setTotalItems(dataObj[listKeyName].total);
            setListState(list);
          }}>
          {children}
        </QueryList>
        {renderPaginator()}
      </Fragment>
    </ListContext.Provider>
  );
};

GqlQueryList.propTypes = {
  gqlQueryFn: PropTypes.func.isRequired,
  url: PropTypes.string,
  customErrorMessage: PropTypes.string,
  listKeyName: PropTypes.string,
  showMoreIncrementAmount: PropTypes.number,
};

GqlQueryList.defaultProps = {
  url: api.graphql(),
  customErrorMessage: '',
  listKeyName: '',
  showMoreIncrementAmount: 5,
};

export default GqlQueryList;
