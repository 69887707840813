import React, { Fragment, useState } from 'react';
import GAButton from 'components/GAButton';
import GetSvg from 'vendor/GetSvg';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { getQuoteOrSaleTotal } from 'functions/getQuoteOrSaleTotal.func';
import EFTContent from './AccordionContent/EFT';
import PaymentContent from './AccordionContent/MakePayment';
import CancellationModal from './CancelOrderConfirmationModal';

export default ({ data, index }) => {
  const [accordionHeight, setAccordionHeight] = useState(0);
  const [isCardClicked, setIsCardClicked] = useState(false);
  const [isEFTClicked, setIsEFTClicked] = useState(false);

  const accordionTransitionDuration = 500;
  const handleEFTClick = () => {
    if (!isEFTClicked) {
      setIsCardClicked(false);
      setIsEFTClicked(true);
      setAccordionHeight('auto');
    } else {
      closeAccordion();
    }
  };
  const handleCardClick = () => {
    if (!isCardClicked) {
      setIsEFTClicked(false);
      setIsCardClicked(true);
      setAccordionHeight('auto');
    } else {
      closeAccordion();
    }
  };
  const closeAccordion = () => {
    setAccordionHeight(0);
    window.setTimeout(() => {
      // Wait until animation is finished then clear state rendering content
      setIsEFTClicked(false);
      setIsCardClicked(false);
    }, accordionTransitionDuration);
  };

  // need to check if both quote line and order lines exist before displaying order
  const quoteAndOrderLinesExist = () => {
    return (
      data.sale_quote.sale_quote_lines.length > 0 &&
      data.sale_order.sale_order_lines.length > 0
    );
  };

  const getQuoteLinesOrOrderLines = (
    saleQuoteLines = [],
    saleOrderLines = [],
  ) => {
    if (saleQuoteLines.length > 0) {
      return saleQuoteLines;
    }
    if (saleOrderLines.length > 0) {
      return saleOrderLines;
    }
    return [];
  };

  const OrderAccordionContent = () => {
    return (
      <AnimateHeight height={accordionHeight}>
        {isCardClicked && (
          <PaymentContent
            sale_id={data.id}
            sale_outstanding_amount={data.sale_invoices[0].outstanding_amount}
            sale_total_amount={data?.sale_quote?.total}
          />
        )}
        {isEFTClicked && (
          <EFTContent sale_order_number={data.sale_order.sale_order_number} />
        )}
      </AnimateHeight>
    );
  };

  const lines = getQuoteLinesOrOrderLines(
    data.sale_quote.sale_quote_lines,
    data.sale_order.sale_order_lines,
  );

  const [isVoidModalVisible, setVoidModalState] = useState(false);
  const openVoidModal = () => setVoidModalState(true);

  const disableVoidButton = () => {
    const order_total = getQuoteOrSaleTotal(
      data.sale_quote.total,
      data.sale_order.total,
    );
    return data.sale_invoices[0].outstanding_amount < order_total;
  };

  return (
    quoteAndOrderLinesExist && (
      <Fragment>
        <div className="latest-order-desktop-container">
          <div>
            <div className="dashboard-heading">
              {index === 0 && (
                <div className="first-heading-first-content heading-child">
                  Order No.
                </div>
              )}
              {index === 0 && <div className="heading-child">Items</div>}
              {index === 0 && <div className="heading-child">Total</div>}
              {index === 0 && (
                <div className="heading-child">Total Outstanding</div>
              )}
              {index === 0 && (
                <div className="heading-child">Remaining Days</div>
              )}
            </div>
            <div />
            <div />
            <div />
          </div>
          <div className="dashboard-content">
            <div className="dashboard-content__row">
              <div className="first-heading-first-content dashboard-content__column">
                {data.sale_order.sale_order_number}
              </div>
              <div className="dashboard-content__column">
                <div className="dashboard-content__images">
                  {lines.slice(0, 3).map((valueObj, i) => {
                    return (
                      <img
                        className="dashboard-content__img"
                        key={i}
                        alt="items"
                        src={valueObj.product.image}
                      />
                    );
                  })}
                  {lines.length > 3 && (
                    <span className="dashboard-content__ellipsis"> ...</span>
                  )}
                </div>
              </div>
              <div className="dashboard-content__column">
                {`R${getQuoteOrSaleTotal(
                  data.sale_quote.total,
                  data.sale_order.total,
                )}`}
              </div>
              <div className="dashboard-content__column">
                {`R${data.sale_invoices[0].outstanding_amount}`}
              </div>
              <div className="dashboard-content__column">
                {data.sale_invoices[0].remaining_days ? (
                  `${data.sale_invoices[0].remaining_days} days remaining`
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>

            <div className="action-button view-btn">
              <Link to={{ pathname: '/orders', target_id: data.id }}>
                <GetSvg svg="eye" wrapperClass="svg" />
              </Link>
            </div>

            <GAButton
              label="card_payment_button"
              className={`action-button card-btn ${
                isCardClicked ? 'card-btn-active' : ''
              }`}
              type="button"
              onClick={handleCardClick}>
              <GetSvg svg="my_payment" wrapperClass="svg" />
            </GAButton>

            <GAButton
              label="eft_payment_button"
              className={`action-button eft-btn ${
                isEFTClicked ? 'action-button-active' : ''
              }`}
              type="button"
              onClick={handleEFTClick}>
              <strong>EFT</strong>
            </GAButton>

            <GAButton
              label="cancel_payment_button"
              className={`action-button card-btn void-sale-btn ${
                isVoidModalVisible ? 'card-btn-active' : ''
              }`}
              type="button"
              disabled={disableVoidButton()}
              onClick={openVoidModal}>
              <GetSvg svg="cancel_order" wrapperClass="svg" />
            </GAButton>
          </div>
          <OrderAccordionContent />
        </div>
        <div className="latest-orders-mobile-container">
          <div className="latest-orders__first-row">
            <div className="latest-orders__first-row__col-1">
              <span>
                <span className="order-mobile-headings">Order No:</span>
                <span className="order-mobile-content">
                  {data.sale_order.sale_order_number}
                </span>
              </span>
              <div>
                <div className="order-mobile-content dashboard-content__images">
                  {lines.slice(0, 3).map((valueObj, i) => {
                    return (
                      <img
                        className="item-imgs"
                        key={i}
                        alt="items"
                        src={valueObj.product.image}
                      />
                    );
                  })}
                  {lines.length > 3 && (
                    <span className="dashboard-content__ellipsis"> ...</span>
                  )}
                </div>
              </div>
            </div>
            <div className="latest-orders__first-row__col-2">
              <span>
                <div className="order-mobile-headings">Total:</div>
                <div className="order-mobile-content">
                  {`R${getQuoteOrSaleTotal(
                    data.sale_quote.total,
                    data.sale_order.total,
                  )}`}
                </div>
              </span>
              <span>
                <div className="order-mobile-headings">Outstanding:</div>
                <div className="order-mobile-content">
                  {`R${data.sale_invoices[0].outstanding_amount}`}
                </div>
              </span>
              <span>
                <div className="order-mobile-headings">Remaining Days:</div>
                <div className="order-mobile-content days-content">
                  {' '}
                  {data.sale_invoices[0].remaining_days ? (
                    `${data.sale_invoices[0].remaining_days} days`
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="latest-orders__second-row">
            <div className="action-button headings-mobile view-btn">
              {' '}
              <Link to={{ pathname: '/orders', target_id: data.id }}>
                <strong>VIEW</strong>
              </Link>
            </div>
            <GAButton
              label="card_payment_button"
              className={`action-button headings-mobile card-btn ${
                isCardClicked ? 'action-button-active' : ''
              }`}
              type="button"
              onClick={handleCardClick}>
              <strong>CREDIT CARD</strong>
            </GAButton>

            <GAButton
              label="eft_payment_button"
              className={`action-button eft-btn ${
                isEFTClicked ? 'action-button-active' : ''
              }`}
              type="button"
              onClick={handleEFTClick}>
              <strong>EFT</strong>
            </GAButton>
            {!disableVoidButton() && (
              <GAButton
                label="cancel_payment_button"
                className={`action-button cancel-order-btn ${
                  isVoidModalVisible ? 'action-button-active' : ''
                }`}
                type="button"
                onClick={openVoidModal}>
                <strong>CANCEL</strong>
              </GAButton>
            )}
          </div>
          <OrderAccordionContent />
        </div>
        {isVoidModalVisible && (
          <CancellationModal
            isOpen={isVoidModalVisible}
            close={setVoidModalState}
            data={data}
          />
        )}
      </Fragment>
    )
  );
};
